import $ from 'jquery';

const anchorsScroll = {

  init: function () {

    $('a[href*="#"]')
      .not('[href="#"]')
      .not('[href="#0"]')
      .click(function () {
        if(areUrlsEqual(location.href, this.href)){
          let target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          scrollTo(target);
        }
      });

    function scrollTo(target) {
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top - 100,
        }, 1000)
      }
    }

    function areUrlsEqual(url1, url2) {
      let urlObject1 = new URL(url1);
      let urlObject2 = new URL(url2);

      let urlWithoutParamsHash1 = urlObject1.origin + urlObject1.pathname;
      let urlWithoutParamsHash2 = urlObject2.origin + urlObject2.pathname;

      return urlWithoutParamsHash1 === urlWithoutParamsHash2;
    }
  },
}

export default anchorsScroll;
