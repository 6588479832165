/* eslint-disable */
const YoutubeBg = {

  init: function () {

    let youtubeBgs = document.querySelectorAll('[data-vbg]');
    if (!youtubeBgs || youtubeBgs.length === 0) return;
    const videoBackgrounds = new VideoBackgrounds(youtubeBgs, {
      'lazyloading': true,
      'loop': true,
    });

    youtubeBgs.forEach((bg) => {
      bg.addEventListener('video-background-ready', function () {
        const iframe = bg.querySelector('iframe');
        if (iframe) {
          iframe.style.opacity = '1';
        }
      });
    });
  },
}

export default YoutubeBg;
