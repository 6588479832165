import Swiper from 'swiper';
import 'swiper/css';

/* eslint-disable */
export default (gsap, ScrollTrigger) => {
  const blocksPin = document.querySelectorAll('.pin-scroll-block');
  blocksPin.forEach(block => {
    const swiper = block.querySelector('.JS-swiper-pin');

    const swiperWhat = new Swiper(swiper, {
      direction: 'vertical',
      loop: false,
      allowTouchMove: false,
      slidesPerView: 1,
      freeMode: true,
      speed: 1300,
      delay: 0,
    });
    swiperWhat;

    gsap.registerPlugin(ScrollTrigger);
    const slides = swiperWhat.slides;
    const images = swiperWhat.slidesEl.querySelectorAll('.images-wrapper .images .image');
    const inner = block.querySelector('.pin-scroll-content');
    let starts = 'center center';
    const slidesHeight = block.querySelector('.swiper').scrollHeight * 2.5;

    gsap.to(inner, {
      scrollTrigger: {
        trigger: inner,
        start: starts,
        endTrigger: slides[slides.length - 1],
        pin: true,
        scrub: true,
        end: ()=> "+=" + slidesHeight,
        onEnter: ()=> {
          block.classList.add('show');
        },
        onEnterBack: ()=> {
          block.classList.add('show');
        },
        onLeave: ()=> {
          block.classList.remove('show');
        },
        onLeaveBack: ()=> {
          block.classList.remove('show');
        },
        onUpdate: self => {
          // killWhenFast(self);
          animation(self);
        },
      },
    })

    function animation(self) {
      setTimeout(function () {
        let getProgress = Number(self.progress.toFixed(100) * 100);
        const sliderCount = images.length;
        const step = 100 / sliderCount;
        let prev = 0;
        let count = 0;
        let next = prev + (step * slides[0].querySelectorAll('.images .image').length);

        slides.forEach(() => {
          changeSlider(getProgress, prev, next, count);
          prev = next + 1;
          next = next + (step * slides[count + 1]?.querySelectorAll('.images .image').length);
          count = count + 1;
        });
      }, 500);
    }

    function changeSlider(progress, animateBefore, animateAfter, elemIndex, changeSlide = true) {
      if (progress >= animateBefore && progress <= animateAfter) {
        if (changeSlide) {
          swiperWhat.slideTo(elemIndex);
        }

        const current = slides[swiperWhat.activeIndex];
        const full = animateAfter - animateBefore;
        const now = progress - animateBefore;
        const percent = (now / full) * 100;
        const imagesWrapper = current.querySelector('.images');
        const images = imagesWrapper.querySelectorAll('.image');
        const count = 100 / images.length;
        let currentImage = 0;

        for (let index = 1; index <= images.length; index++) {
          if (percent > count * (index - 1) && percent <= count * index) {
            currentImage = index - 1;
          }
        }

        const scroll = (currentImage) * imagesWrapper.clientHeight;
        gsap.to(imagesWrapper, {y: -scroll, duration: 0.5});
      }
    }
  });

  const blocksPinGrey = document.querySelectorAll('.pin-scroll-grey-block');
  blocksPinGrey.forEach(block => {
    const swiper = block.querySelector('.JS-swiper-pin');

    const swiperWhat = new Swiper(swiper, {
        direction: 'vertical',
        loop: false,
        allowTouchMove: false,
        slidesPerView: 1,
        freeMode: true,
        speed: 1000,
        delay: 0,
    });
    swiperWhat;

    gsap.registerPlugin(ScrollTrigger);
    const slides = swiperWhat.slides;
    const inner = block.querySelector('.pin-scroll-grey-content');
    const slidesHeight = block.querySelector('.swiper').scrollHeight * 2;
    let starts = 'center center';

    gsap.to(inner, {
      scrollTrigger: {
        trigger: inner,
        start: starts,
        endTrigger: slides[slides.length - 1],
        pin: true,
        scrub: true,
        end: ()=> "+=" + slidesHeight,
        onEnter: ()=> {
          block.classList.add('show');
        },
        onLeave: ()=> {
          block.classList.remove('show');
        },  
        onEnterBack: ()=> {
          block.classList.add('show');
        },
        onLeaveBack: ()=> {
          block.classList.remove('show');
        },  
        onUpdate: self => {
          killWhenFast(self);
          animation(self);
        },
      },
    })

    function animation(self) {
        setTimeout(function () {
            let getProgress = Number(self.progress.toFixed(1) * 90);
            const sliderCount = slides.length;
            const step = 100 / sliderCount;
            let prev = 0;
            let count = 0;
            let next = step + prev;

            slides.forEach(() => {
              changeSlider(getProgress, prev, next, count);
              prev = next + 1;
              next = next + step;
              count = count + 1;
            });
        }, 500);
    }

    function changeSlider(progress, animateBefore, animateAfter, elemIndex, changeSlide = true) {
        if (progress >= animateBefore && progress <= animateAfter) {
          if (changeSlide) {
            swiperWhat.slideTo(elemIndex);
          }
        }
    }
  });

  const fadeIns = document.querySelectorAll('.fade-in');
  fadeIns.forEach(element => {
    const delay = element.dataset.delay ? element.dataset.delay : 0;
    gsap.fromTo(element,
      {opacity: 0.1},
      {opacity: 1, duration: 3, delay: delay, scrollTrigger: element}
    );
  });

  const fadeUps = document.querySelectorAll('.fade-up');
  fadeUps.forEach(element => {
    const delay = element.dataset.delay ? element.dataset.delay : 0;
    gsap.fromTo(element,
      {opacity: 0, y: '20%'},
      {opacity: 1, y: '0%', duration: 1.5, delay: delay, scrollTrigger: element}
    );
  });

  const lushGarden = document.querySelector('.lush-garden .lush-garden__body-inner');
  if (lushGarden) {
    const fadeUpsLush = lushGarden.querySelectorAll('.lush-fade-up');
    fadeUpsLush.forEach(element => {
      const delay = element.dataset.delay ? element.dataset.delay : 0;
      gsap.fromTo(element,
        {opacity: 0, y: '20%'},
        {opacity: 1, y: '0%', duration: 1.5, delay: delay, scrollTrigger: lushGarden}
      );
    });
  }
}

function killWhenFast(self) {
  let tween = self.getTween();
  tween && Math.abs(self.getVelocity()) > 2500 && tween.progress(1);
}
