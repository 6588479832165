/* eslint-disable */
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import optimizedResize from './helpers/optimized-resize';
import updateScrollTriggerOnResize from './helpers/update-scroll-trigger-on-resize.js';
import baseAnimation from './parts/components/base-animation.js';
import AppHeight from './parts/components/app-height';
import Header from './parts/layout/header';
import YoutubeBg from './parts/components/youtube-bg';
import anchorsScroll from './parts/components/anchor-scroll';
import 'youtube-background';

gsap.registerPlugin(ScrollTrigger);

window.addEventListener('load', () => {
  YoutubeBg.init();
  optimizedResize();
  updateScrollTriggerOnResize(ScrollTrigger);
  baseAnimation(gsap, ScrollTrigger);
  AppHeight.init();
  Header.init(ScrollTrigger);
  anchorsScroll.init();
});

window.addEventListener('resize', () => {
});
